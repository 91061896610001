
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { namespace } from "s-vuex-class";
import EducationHero from "@/components/Pages/Education/EducationHero.vue";
import EducationBlock from "@/components/Pages/Education/EducationBlock.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";
import EducationPriceBlock from "@/components/Pages/Education/EducationPriceBlock.vue";
import { IPriceByStudent } from "@/interfaces/IPriceByStudent";

const StudentModule = namespace("student");
const PriceModule = namespace("price");

@Options({
  components: {
    EducationPriceBlock,
    ForwardHeader,
    EducationBlock,
    EducationHero,
    DefaultLayout,
  },
})
export default class Education extends Vue {
  @StudentModule.Action("findAll")
  public findExamAndEducation: any;

  @StudentModule.Getter("getDataList")
  public examAndEducationList: any;

  @StudentModule.Getter("getIsLoading")
  public loading: any;

  @PriceModule.Action("findAll")
  public pricesByStudent!: (options: any) => Promise<void>;

  @PriceModule.Getter("getDataList")
  public prices!: Array<IPriceByStudent>;

  @PriceModule.Getter("getIsLoading")
  public pricesLoading!: boolean;

  public mounted(): void {
    this.findExamAndEducation({
      resource: "students/exam-and-education/student/current",
      hideSuccessMessage: true,
    });

    this.pricesByStudent({
      resource: "/prices/by-student/current",
    });
  }
}
